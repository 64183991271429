export function injectHeader() {
    const header = require('html-loader!/src/blocks/general/header-nav/header-nav.html').default;
    const headerHtml = document.querySelector<HTMLElement>('.header');


    if (!headerHtml) {
        return;
    }

    headerHtml.innerHTML = header;

    const headerMenuItems = document.querySelectorAll<HTMLDivElement>('.menu');
    const burgerMenu = document.querySelector<HTMLDivElement>('.header-burger');
    const bodyClassList = document.body.classList;

    burgerMenu.addEventListener('click', () => {
        headerMenuItems.forEach(menuItem => menuItem.classList.toggle('header-burger__active'));
        bodyClassList.toggle('lock');

        window.addEventListener('resize', (event) => {
            let screenWidth = (event.target as Window).innerWidth;

            if (screenWidth > 980) {
                headerMenuItems.forEach(menuItem => menuItem.classList.remove('header-burger__active'));
                bodyClassList.remove('lock');
            }
        })
    })
}
