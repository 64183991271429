import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Swiper, {Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination]);

new Swiper('.slider-container', {
    navigation: {
        nextEl: '.next-slide-btn',
        prevEl: '.prev-slide-btn'
    },
    pagination: {
        el: '.slider-pagination',
    },
    spaceBetween: 16,
    grabCursor: true
})

new Swiper('.customer-experience-slider', {
    slidesPerView: 'auto',
    watchOverflow: true,
    spaceBetween: 24,
    grabCursor: true
})
